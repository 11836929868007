<template>
    <agile :options="sliderOption" ref="menu">
        <div class="slide" v-for="(item, index) in menuItems" :key="index"
             :class="'slide--' + index"
             v-on:click="getSelectedItem(index)">
            <div class="item fx-col" :class="{'active': selectedItem === item}">
                <div class="corner-in"></div>
                <div class="item-body">{{item}}</div>
                <div class="corner-in corner-close"></div>
            </div>
        </div>
    </agile>
</template>

<script>
    import {VueAgile} from 'vue-agile';

    export default {
        name: "SectionMenu",
        components: {
            agile: VueAgile,
        },
        props: {
            menuItems: Array,
            defaultItem: { type: String, default: '' }
        },
        data: function () {
            return {
                sliderOption: {
                    initialSlide: 0,
                    dots: false,
                    centerMode: true,
                    navButtons: false,
                    slidesToShow: 3,
                    responsive: [
                        {
                            breakpoint: 900,
                            settings: {
                                slidesToShow: 3
                            }
                        },
                        {
                            breakpoint: 1279,
                            settings: {
                                slidesToShow: 4
                            }
                        },
                        {
                            breakpoint: 1439,
                            settings: {
                                slidesToShow: 5
                            }
                        },
                    ]
                },
                selectedItem: this.defaultItem,
            }
        },
        methods: {
            getSelectedItem(index) {
                this.$refs.menu.goTo(index);
                this.selectedItem = this.menuItems[index];
                this.$emit('selectItem', this.selectedItem );
            }
        },
        mounted() {
            const index = this.menuItems.findIndex( item => item === this.defaultItem);
            this.getSelectedItem(index);
        }
    }
</script>

<style scoped lang="scss">
    .item {
        font-weight: bold;
        font-size: 13px;
        line-height: 13px;
        text-transform: uppercase;
        cursor: pointer;

        &:not(.active) {
            .corner-in {
                display: none;
            }
            opacity: 0.3;
        }
    }

    .item-body {
        white-space: nowrap;
        padding: 0 20px;
        align-self: center;
    }

</style>
